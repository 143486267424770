import atob from "atob";

function decryptoToken64Data(data) {
  try{  
    let token = atob(data)
    return token
  }catch(error) {
    console.log(error, 'error');
  }

}


function decrypto64Data(data) {
  let errorString = 0;
  let newData = data.split("").filter((val, index) => {
    if (val === "=") {
      errorString += 1;
    }
    return val;
  });
  newData = newData.join("").replace(/\=/gi, "")
  console.log(newData, "newData");
  // const uint8Array = new Uint8Array(data);
  // console.log(uint8Array, "uint8Array");
  // const decodedData = uint8Array.slice(0, uint8Array.length - 9);
  // // console.log(decodedData, "decodedData");
  // //转成base64
  // let base64 = "";
  // decodedData.forEach((data) => {
  //   base64 += String.fromCharCode(data);
  // });
  // console.log(base64);
  // return base64;
  let substringNum = null;
  if(errorString > 0 && errorString <= 1) {
    substringNum = 8 - errorString;
  } else if(errorString > 0 && errorString <= 3) {
    substringNum = 9 - errorString;
  } else {
    substringNum = 6 - errorString;
  }
 
  // console.log(substringNum);
  // console.log(newData, "newData");
  let base64 = newData.substring(0, newData.length - substringNum);
  // console.log(data);
  // console.log(atob(base64), "base64");
  console.log(decodeURIComponent(escape(atob(atob(base64)))), "base64");
  return decodeURIComponent(escape(atob(atob(base64))));
}

export  {decrypto64Data, decryptoToken64Data};
