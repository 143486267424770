.container {
  width: 100vw;
  height: 100%;
  background: url("../assets/comment_send_gold/bg.png") repeat;
  background-size: 100% auto;
  background-color: #ffbd70;
  .title {
    text-align: center;
    font-size: 10vw;
  }
  .images {
    margin: 0 auto;
    // width: 100%;
    // padding: 20px;
    padding-top: 2vw;
    width: 90vw;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      // margin-left: -10vw;
    }
  }
  .table {
    // position: absolute;
    width: 83.84vw;
    height: 87.94vw;
    // left: 24px;
    // top: 302px;

    background: #ffffff;
    border-radius: 2.56vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_img {
      padding-top: 3.8vw;
    }
    .tips {
      width: 77.17vw;
      height: 5.61vw;
      //   left: calc(50% - 301px / 2);
      //   top: 351px;
      font-size: 2.561vw;
      background: linear-gradient(273.94deg, #ffe6e7 0%, #fff9eb 99.57%);
      border-radius: 1.28vw;
      margin-top: 3.8vw;
      text-align: center;
      line-height: 5.61vw;
      color: #ac634c;
    }
    .content {
      margin-top: 5.12vw;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      // height: 59vw;
      .content_header {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f7fbfd;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
        }
      }
      .content_list {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
        }
      }
      .content_bottom {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          color: #5278ff;
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
          background: #f7fbfd;
        }
      }
    }
  }
  .rules {
    width: 70vw;
    padding-top: 3vw;
    margin: 5vw auto;
    border-radius: 22px;
    // border: 2px solid #000;
    // background: #fff;
    padding-bottom: 5vw;

    .title {
      font-size: 4vw;
      color: #333;
      text-align: center;
      font-weight: 600;
    }
    .rules_content {
      padding-top: 20px;
      color: #333;
      font-size: 3.1vw;
      line-height: 5vw;
      min-height: 20vw;
      text-align: start;
      width: 60vw;
      padding-left: 5vw;
      color: #000;
      font-family: PingFang SC;
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17px; /* 141.667% */
      // text-align: center;
      .rules_item {
        padding-bottom: 1vw;
      }
    }
  }
  .button {
    width: 83.84vw;
    height: 10.256vw;
    font-size: 3.84vw;
    line-height: 10.256vw;
    margin: 30px auto 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(89.74deg, #6dc2ff -0.21%, #8a61ff 99.33%);
    border-radius: 30px;
  }
  .disable_button {
    width: 83.84vw;
    height: 10.256vw;
    font-size: 3.84vw;
    line-height: 10.256vw;
    margin: 30px auto 0;
    text-align: center;
    color: #075cff;
    border: 1px solid #075cff;
    border-radius: 30px;
  }
}
