import styles from "./App.module.scss";
import request from "../utils/request.js";
import react, { useEffect, useState } from "react";
import { userAgent, handleJsBridge } from "../utils/jsbridge";
import { decryptoToken64Data } from "../utils/decrypto";
function BuyMonthVipLanmao() {
  const [data, setData] = useState(null);
  const [initStatus, setInitStatus] = useState(false);
  const [isWeb, setIsWeb] = useState('');
  // let isWeb = ''
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const token = urlParams.get("token")
      ? decryptoToken64Data(urlParams.get("token"))
      : "";
    const uid = urlParams.get("uid");
    // isWeb = urlParams.get("isWeb");
    // console.log(token, "token");
    // console.log(isWeb, "isWeb");
    setIsWeb(urlParams.get("isWeb"))
    if (id) {
      getPromoConfig(id, token, uid);
    }
  }, []);
  function getPromoConfig(id, token, uid) {
    request({
      // responseType: "arraybuffer",
    })
      .post("/api/v2/member/activities/info", {
        alias: id,
        token: token,
        uid: parseFloat(uid),
      })
      .then((res) => {
        console.log(res, "res");
        if (res?.data) {
          setData(res.data);
          setInitStatus(true);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function apply() {
    try {
      console.log(isWeb, 'isWeb');
      handleJsBridge("buyVipHome", JSON.stringify({ id: "buyVipHome" }), isWeb);
    } catch (error) {
      console.log(error, "error");
    }
  }
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>{data?.name}</div> */}
      <div className={styles.images}>
        <img src={require("../assets/buy_month_vip_lanmao/logo.png")} />
      </div>
      <div className={styles.rules}>
        <p className={styles.title}>活动规则</p>
        <ul className={styles.rules_content}>
          {data?.desc.split("\n").map((val, index) => {
            return <li className={styles.rules_item}>{val}</li>;
          })}
        </ul>
      </div>
      {initStatus && data?.is_bind !== 1 && (
        <div className={styles.button} onClick={apply}>
          前往开通
        </div>
      )}
      {initStatus && data?.is_bind === 1 && (
        <div className={styles.disable_button}>您已参与过该活动</div>
      )}
    </div>
  );
}
export default BuyMonthVipLanmao;
