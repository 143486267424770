import styles from "./App.module.scss";
import request from "../utils/request.js";
import react, { useEffect, useState } from "react";
import { userAgent, handleJsBridge } from "../utils/jsbridge";
import { decryptoToken64Data } from "../utils/decrypto";
function LuckyGoose() {
  const [data, setData] = useState(null);
  const [isWeb, setIsWeb] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const token = urlParams.get("token")
      ? decryptoToken64Data(urlParams.get("token"))
      : "";
    const uid = urlParams.get("uid");
    if (id) {
      getPromoConfig(id, token, uid);
    }
    setIsWeb(urlParams.get("isWeb"));
  }, []);
  function getPromoConfig(id, token, uid) {
    request({
      // responseType: "arraybuffer",
    })
      .post("/api/v2/member/activities/info", {
        alias: id,
        token: token,
        uid: parseFloat(uid),
      })
      .then((res) => {
        console.log(res, "res");
        if (res?.data) {
          setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function apply() {
    try {
      handleJsBridge(
        "rechargeHome",
        JSON.stringify({ id: "first_recharge" }),
        isWeb
      );
    } catch (error) {
      console.log(error, "error");
    }
  }
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>{data?.name}</div> */}
      <div className={styles.images}>
        <img src={require("../assets/lucky_goose/logo.png")} />
      </div>
      {/* <div className={styles.table}>
        <div className={styles.title_img}>
          <img src={require("../assets/recharge_send_coin/title.png")} />
        </div>
      </div> */}
      <div className={styles.rules}>
        <p className={styles.title}>报名阶段</p>
        <ul className={styles.rules_content}>
          <li className={styles.rules_item}>
            <span className={styles.rules_item_num}>1.</span>
            报名阶段：11月3日 下午8点—11月10日 晚上8点;
          </li>
          <li className={styles.rules_item}>
            <span className={styles.rules_item_num}>2.</span>
            限时补尾款阶段：11月10日晚9点30—11月11日 晚上8点截止 ;
          </li>
        </ul>
      </div>
      <div className={styles.rules}>
        <p className={styles.title}>活动规则</p>
        <ul className={styles.rules_content}>
          <li className={styles.rules_item}>
            <span className={styles.rules_item_num}>1.</span>
            报名阶段内，用户有单笔充值100或以上的记录（包括金币，会员卡，金币打折卡），即可默认自动报名活动。
          </li>
          <li className={styles.rules_item}>
            <span className={styles.rules_item_num}>2.</span>
            我们以11月10日，当日晚9点15的福彩3D开奖号码结果，作为我们的幸运鹅评判标准，如当晚开奖号码为2
            6 7 ，那么所有参与报名，且黑料百科ID尾号为2，6，7
            的用户，为我们本次活动的幸运鹅。
          </li>
        </ul>
      </div>
      <div className={styles.rules}>
        <p className={styles.title}>幸运鹅福利</p>
        <ul className={styles.rules_content}>
          <li className={styles.rules_item}>
            充值100报名的幸运鹅用户，在11.11付款阶段内再次购买一张300元的新人钜惠永久卡，联系客服申请，可以直接升级成为平台永不出售的全站畅看股东卡，如果超时未补钱，默认放弃幸运鹅奖励，过期再充不予赠送。
          </li>
          <li className={styles.rules_item}>
            充值300报名的幸运鹅用户，联系客服申请，无需补钱，可直接升级为股东卡。
          </li>
          <li className={styles.rules_item}>
            股东卡说明：所有金币视频，金币帖子，包含后续更新的所有金币内容，均可不消耗金币永久免费观看，股东卡永不出售，为限量绝版卡。
          </li>
          <li className={styles.rules_item}>
            注意：本次活动股东权益卡不是自动到账，需满足要求后联系客服后登记后才可以，由于往年经验，双11流量高峰期，参与中奖人数量庞大，所以股东卡会在用户满足要求，联系客服登记后48小时陆续到账。
          </li>
        </ul>
      </div>
      <div className={styles.rules}>
        <p className={styles.title}>常见问题</p>
        <ul className={styles.rules_content}>
          <li className={styles.rules_item}>
            我的ID在哪里查看？ 点击底部导航—我的—个人资料即可查看。{" "}
          </li>
          <li className={styles.rules_item}>
            福彩3D开奖结果在哪看？
            百度搜索，福彩3D开奖结果，11月10日晚9点后可看当日开奖结果。
          </li>
          <li className={styles.rules_item}>
            活动真实有效吗？会不会作弊？
            放心，各位家人，福彩3D开奖结果是由国家福利彩票统一开奖，国家公证处公正，且已平稳运营多年，没有人有任何做假的机会，你可以提前记录好自己的iD，看看是否能成为我们黑料百科的幸运鹅
          </li>
        </ul>
      </div>
      {/* <div style={{ paddingBottom: "30px" }}>
        <div className={styles.button} onClick={apply}>
          前往充值
        </div>
      </div> */}
    </div>
  );
}
export default LuckyGoose;
