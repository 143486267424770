import styles from "./App.module.scss";
import request from "../utils/request.js";
import react, { useEffect, useState } from "react";
import { userAgent, handleJsBridge } from "../utils/jsbridge";
import { decryptoToken64Data } from "../utils/decrypto";
function RechargeSendCoin() {
  const [data, setData] = useState(null);
  const [isWeb, setIsWeb] = useState("");
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const id = urlParams.get("id");
    const token = urlParams.get("token")
      ? decryptoToken64Data(urlParams.get("token"))
      : "";
    const uid = urlParams.get("uid");
    if (id) {
      getPromoConfig(id, token, uid);
    }
    setIsWeb(urlParams.get("isWeb"));
  }, []);
  function getPromoConfig(id, token, uid) {
    request({
      // responseType: "arraybuffer",
    })
      .post("/api/v2/member/activities/info", {
        alias: id,
        token: token,
        uid: parseFloat(uid),
      })
      .then((res) => {
        console.log(res, "res");
        if (res?.data) {
          setData(res.data);
        }
      })
      .catch((error) => {
        console.log(error, "error");
      });
  }

  function apply() {
    try {
      handleJsBridge(
        "rechargeHome",
        JSON.stringify({ id: "first_recharge" }),
        isWeb
      );
    } catch (error) {
      console.log(error, "error");
    }
  }
  return (
    <div className={styles.container}>
      {/* <div className={styles.title}>{data?.name}</div> */}
      <div className={styles.images}>
        <img src={require("../assets/recharge_send_coin/logo.png")} />
      </div>
      <div className={styles.table}>
        <div className={styles.title_img}>
          <img src={require("../assets/recharge_send_coin/title.png")} />
        </div>
        {/* <div className={styles.tips}>
          首充金额达到指定额度，即可获得现金奖励，限领1次
        </div> */}
        <div className={styles.content}>
          <div>
            <ul className={styles.content_header}>
              <li className={styles.content_items}>充值金额（元）</li>
              <li className={styles.content_items}>赠送金额（元）</li>
            </ul>
            {data?.args.map((val, index) => {
              return (
                <ul className={styles.content_list} key={index}>
                  <li className={styles.content_items}>{val.amount}</li>
                  <li className={styles.content_items}>{val.bonus}</li>
                </ul>
              );
            })}
          </div>

          {/* <ul className={styles.content_bottom}>
            <li className={styles.content_items}>首充金额：</li>
            <li className={styles.content_items}>赠送金额：</li>
          </ul> */}
        </div>
      </div>
      <div className={styles.rules}>
        <p className={styles.title}>活动规则</p>
        <ul className={styles.rules_content}>
          {data?.desc.split("\n").map((val, index) => {
            return <li className={styles.rules_item}>{val}</li>;
          })}
        </ul>
      </div>
      <div style={{ paddingBottom: "30px" }}>
        <div className={styles.button} onClick={apply}>
          前往充值
        </div>
      </div>
    </div>
  );
}
export default RechargeSendCoin;
