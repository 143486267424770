function handleJsBridge(page, data, isWeb) {
  try {
    console.log(isWeb, "isWeb");
    console.log(page, 'page')
    if (isWeb) {
      if (page) {
        window.parent.postMessage(
          {
            action: page,
            data: data ? data : "",
          },
          "*"
        );
      }
      return false;
    }
    // 根据不同的终端，执行不同的方法
    if (userAgent() === "isIOS") {
      setupWKWebViewJavascriptBridge(function (bridge) {
        /* Initialize your app here */
        bridge.registerHandler(
          "testJavascriptHandler",
          function (data, responseCallback) {
            console.log("iOS called testJavascriptHandler with", data);
            responseCallback({ "Javascript Says": "Right back atcha!" });
          }
        );

        bridge.callHandler(
          "callAppMethod",
          { action: page, params: data },
          function (response) {
            console.log("JS got response", response);
          }
        );
      });
      // window.WKWebViewJavascriptBridge.callAppMethod(page, data)
    } else if (userAgent() === "isAndroid") {
      // connectWebViewJavascriptBridge(callback);
      window.WebViewJavascriptBridge.callAppMethod(page, data);
    }
  } catch (error) {
    console.log(error, "call methods error");
  }
}

// ios下的执行方法，注意这段代码需要ios使用的是WKWebView，如果使用的是UIWebView，可自行百度
function setupWKWebViewJavascriptBridge(callback) {
  if (window.WKWebViewJavascriptBridge) {
    return callback(window.WKWebViewJavascriptBridge);
  }
  if (window.WKWVJBCallbacks) {
    return window.WKWVJBCallbacks.push(callback);
  }
  window.WKWVJBCallbacks = [callback];

  // 此处是判断浏览器内核，核心通信是调用postMessage方法
  if (window.webkit)
    window.webkit.messageHandlers.iOS_Native_InjectJavascript.postMessage(null);
}

// 安卓下的配置方法，原生会在webview上定义全局的WebViewJavascriptBridge对象
function connectWebViewJavascriptBridge(callback) {
  // 判断window.WebViewJavascriptBridge是否存在
  if (window.WebViewJavascriptBridge) {
    callback(window.WebViewJavascriptBridge);
  } else {
    // 如果不存在则添加监听事件
    document.addEventListener(
      "WebViewJavascriptBridgeReady",
      function () {
        callback(window.WebViewJavascriptBridge);
      },
      false
    );
  }
}

function userAgent() {
  if (/(iPhone|iPad|iPod|iOS)/i.test(navigator.userAgent)) {
    // console.log("isIOS");
    return "isIOS";
  } else if (/(Android)/i.test(navigator.userAgent)) {
    // console.log("isAndroid");
    return "isAndroid";
  } else {
    // console.log("isPC");
    return "isPC";
  }
}
export { userAgent, handleJsBridge };
