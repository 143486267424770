.container {
  width: 100vw;
  height: 100%;
  background: url("../assets/first_recharge/pics.png") no-repeat;
  background-size: 100% auto;
  background-color: #eeeff4;
  .title {
    text-align: center;
    font-size: 10vw;
  }
  .images {
    // margin: 0 auto;
    // width: 100%;
    // padding: 20px;
    padding-top: 52vw;
    width: 100vw;
  }
  .table {
    // position: absolute;
    width: 83.84vw;
    min-height: 87.94vw;
    // left: 24px;
    // top: 302px;

    background: #ffffff;
    border-radius: 2.56vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title_img {
      padding-top: 3.8vw;
    }
    .tips {
      width: 77.17vw;
      height: 5.61vw;
      //   left: calc(50% - 301px / 2);
      //   top: 351px;
      font-size: 2.561vw;
      background: linear-gradient(273.94deg, #ffe6e7 0%, #fff9eb 99.57%);
      border-radius: 1.28vw;
      margin-top: 3.8vw;
      text-align: center;
      line-height: 5.61vw;
      color: #ac634c;
    }
    .content {
      margin-top: 5.12vw;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      // height: 59vw;
      .content_header {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #f7fbfd;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
        }
      }
      .content_list {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
        }
      }
      .content_bottom {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          color: #5278ff;
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
          background: #f7fbfd;
        }
      }
      .rules {
        width: 70vw;
        padding-top: 5vw;
        .title {
          font-size: 3.58vw;
          color: #a8b6c9;

          text-align: start;
        }
        .rules_content {
          color: #a8b6c9;
          font-size: 3.07vw;
          line-height: 5vw;
          .rules_item {
          }
        }
      }
    }
  }
  .button {
    width: 83.84vw;
    height: 10.256vw;
    font-size: 3.84vw;
    line-height: 10.256vw;
    margin: 30px auto 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(89.74deg, #6dc2ff -0.21%, #8a61ff 99.33%);
    border-radius: 30px;
  }
}
