import * as React from "react";
import * as ReactDOM from "react-dom/client";
// import { App } from "./App";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import MemberRechargePage from "./member_recharge/App";
import FirstRecharge from "./first_recharge/App";
import BindPhoneVip from "./bind_phone_vip/App";
import BindPhoneGetCoin from "./bind_phone_get_coin/App";
import BuyMonthVipLanmao from "./buy_month_vip_lanmao/App";
import CelebrateCarnival from "./celebrate_carnival/App";
import CommentSendGold from './comment_send_gold/App';
import RechargeSendCoin from './recharge_send_coin/App';
import LuckyGoose from './lucky_goose/App';
const router = createBrowserRouter([
  {
    path: "/",
    redirect: "/recharge_send_vip",
  },
  {
    path: "/recharge_send_vip",
    Component: MemberRechargePage,
  },
  {
    path: "/first_recharge",
    Component: FirstRecharge,
  },
  {
    path: "/bind_phone_send_vip",
    Component: BindPhoneVip,
  },
  {
    path: "/bind_phone_send_coin",
    Component: BindPhoneGetCoin,
  },
  {
    path: "/buy_month_vip_lanmao",
    Component: BuyMonthVipLanmao,
  },
  {
    path: "/3002_celebrate_carnival",
    Component: CelebrateCarnival,
  },
  {
    path: "/comment_send_gold",
    Component: CommentSendGold,
  },
  {
    path: "/recharge_send_coin",
    Component: RechargeSendCoin,
  },
  {
    path: "/lucky_goose",
    Component: LuckyGoose,
  },
]);
const container = document.getElementById("app");
const root = ReactDOM.createRoot(container);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
