import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import {decrypto64Data} from './decrypto.js';
const request = (config) => {
  const instance = axios.create({
    timeout: 20000, //超时配置
    withCredentials: false, //跨域携带cookie
    baseURL: process.env.NODE_ENV === "production" ? process.env.API : "/basic-api",
    ...config, // 自定义配置覆盖基本配置
    headers: {
      site_id: 1000,
      os: 'h5'
    },
  });
  // 添加请求拦截器
  instance.interceptors.request.use(
    function (config) {
      console.log("config:", config);
      return config;
    },
    function (error) {
      // 对请求错误做些什么
      return Promise.reject(error);
    }
  );

  // 添加响应拦截器
  instance.interceptors.response.use(
    function (response) {
      console.log("response:", response.data);
      let data = decrypto64Data(response.data);
      if(data) {
        console.log(data,'data')
        data = JSON.parse(data);
      }
    //   console.log(data, 'data')
      const { code, message } = data
      if(code === 200) {
        return data;
      }
    },
    function (error) {
      console.log("error-request:", error.request);
      if (error.response) {
        if (error.response.status === 401) {
        }
      }
      return Promise.reject(error);
    }
  );
  return instance;
};
export default request;
