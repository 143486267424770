.container {
  width: 100vw;
  min-height: 100vh;
  background: url("../assets/recharge_send_coin/pics.png") no-repeat;
  background-size: 100% auto;
  background-color: #f9b300;
  .title {
    text-align: center;
    font-size: 10vw;
  }
  .images {
    // margin: 0 auto;
    // width: 100%;
    // padding: 20px;
    padding-top: 2vw;
    width: 100vw;
    img {
      width: 100%;
    }
  }
  .table {
    // position: absolute;
    width: 83.84vw;
    min-height: 75vw;
    // left: 24px;
    // top: 302px;

    // background: #ffffff;
    border-radius: 22px;
    border: 1px solid #333;
    background: #fff;
    // border-radius: 2.56vw;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title_img {
      padding-top: 3.8vw;
      width: 45vw;
      img {
        width: 100%;
      }
    }
    .tips {
      width: 77.17vw;
      height: 5.61vw;
      //   left: calc(50% - 301px / 2);
      //   top: 351px;
      font-size: 2.561vw;
      // background: linear-gradient(273.94deg, #ffe6e7 0%, #fff9eb 99.57%);
      border-radius: 1.28vw;
      margin-top: 3.8vw;
      text-align: center;
      line-height: 5.61vw;
      color: #ac634c;
    }
    .content {
      margin-top: 2.12vw;
      display: flex;
      // align-items: center;
      flex-direction: column;
      justify-content: space-between;
      align-content: space-between;
      border-radius: 2px 2px 0px 0px;
      width: 100%;
      // height: 59vw;
      .content_header {
        width: 77vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #fff3c0;
        // border: 1px solid #d9d2ae;
        margin: 0 auto;
        .content_items {
          width: 50%;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
          border: 1px solid #d9d2ae;
          &:last-child {
            margin-left: -1px;
          }
          margin-bottom: -1px;
        }
      }
      .content_list {
        width: 77vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        // flex-wrap: wrap;
        margin: 0 auto;
        .content_items {
          width: 50%;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
          border: 1px solid #d9d2ae;
          &:last-child {
            margin-left: -1px;
          }
          margin-bottom: -1px;
        }
      }
      .content_bottom {
        width: 70vw;
        display: flex;
        align-items: center;
        justify-content: space-between;
        .content_items {
          width: 26.12vw;
          height: 8.25vw;
          display: flex;
          align-items: center;
          justify-content: center;
          font-family: "PingFang SC";
          color: #5278ff;
          font-style: normal;
          font-weight: 400;
          font-size: 3.289vw;
          background: #f7fbfd;
        }
      }
    }
  }
  .rules {
    // width: 70vw;
    padding-top: 5vw;
    width: 83.84vw;
    // min-height: 87.94vw;
    // left: 24px;
    // top: 302px;

    // background: #ffffff;
    border-radius: 2.56vw;
    margin: 0 auto;
    .title {
      font-size: 4vw;
      color: #000;
      font-weight: 900;
      text-align: start;
      margin-bottom: 2px;
    }
    .rules_content {
      color: #000;
      font-size: 3.2vw;
      line-height: 5.5vw;
      .rules_item {
      }
    }
  }
  .button {
    width: 83.84vw;
    height: 10.256vw;
    font-size: 3.84vw;
    line-height: 10.256vw;
    margin: 30px auto 0;
    text-align: center;
    color: #fff;
    background: linear-gradient(0deg, #191d2c 0%, #191d2c 100%);
    border-radius: 30px;
    
  }
}
